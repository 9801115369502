import React from 'react';

import styles from './styles.scss';

const Header = () => (
  <header className={styles.header}>
    <h1>hoenn secret base designer</h1>
  </header>
);

export default Header;
