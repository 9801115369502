export const STRICT_GRID_SPACING = 32;
export const EASY_GRID_SPACING = 8;

export const POOF_DURATION = 600;

export const LOCALSTORAGE_KEY = 'secretbase:save';

export const bases = [
  { id: 'base_0000_1', label: 'Square Cave', type: 'cave' },
  { id: 'base_0005_6', label: 'Long Cave', type: 'cave' },
  { id: 'base_0016_17', label: '2-Room Cave', type: 'cave' },
  { id: 'base_0019_20', label: 'Divided Cave', type: 'cave' },

  { id: 'base_0001_2', label: 'Square Tree', type: 'tree' },
  { id: 'base_0015_16', label: '3-Room Tree', type: 'tree' },
  { id: 'base_0018_19', label: 'Split Tree', type: 'tree' },
  { id: 'base_0021_22', label: 'Long Tree', type: 'tree' },

  { id: 'base_0002_3', label: 'Square Shrub', type: 'shrub' },
  { id: 'base_0011_12', label: 'Divided Shrub', type: 'shrub' },
  { id: 'base_0014_15', label: '2-Room Shrub', type: 'shrub' },
  { id: 'base_0020_21', label: 'Wide Shrub', type: 'shrub' },

  { id: 'base_0003_4', label: 'Square Mountain Cave', type: 'mountain_cave' },
  { id: 'base_0007_8', label: '1-Room Mountain Cave', type: 'mountain_cave' },
  { id: 'base_0009_10', label: 'Split Mountain Cave', type: 'mountain_cave' },
  { id: 'base_0017_18', label: '2-Room Mountain Cave', type: 'mountain_cave' },

  { id: 'base_0004_5', label: 'Square Water Cave', type: 'water_cave' },
  { id: 'base_0010_11', label: 'Winding Water Cave', type: 'water_cave' },
  { id: 'base_0013_14', label: 'Long Water Cave', type: 'water_cave' },
  { id: 'base_0023_24', label: 'Wide Water Cave', type: 'water_cave' },

  { id: 'base_0006_7', label: 'Square Desert Cave', type: 'desert_cave' },
  { id: 'base_0008_9', label: '1-Room Desert Cave', type: 'desert_cave' },
  { id: 'base_0012_13', label: '2-Room Desert Cave', type: 'desert_cave' },
  { id: 'base_0022_23', label: 'U-Shape Desert Cave', type: 'desert_cave' },
];

export const items = [
  { filename: 'rsrh001.png', category: 'table_small' },
  { filename: 'rsrh002.png', category: 'table_small' },
  { filename: 'rsrh003.png', category: 'table_medium' },
  { filename: 'rsrh004.png', category: 'table_medium' },
  { filename: 'rsrh005.png', category: 'table_medium' },
  { filename: 'rsrh006.png', category: 'table_large' },
  { filename: 'rsrh007.png', category: 'table_large' },
  { filename: 'rsrh008.png', category: 'table_large' },
  { filename: 'rsrh009.png', category: 'table_large' },
  { filename: 'rsrh010.png', category: 'chair' },
  { filename: 'rsrh011.png', category: 'chair' },
  { filename: 'rsrh012.png', category: 'chair' },
  { filename: 'rsrh013.png', category: 'chair' },
  { filename: 'rsrh014.png', category: 'chair' },
  { filename: 'rsrh015.png', category: 'chair' },
  { filename: 'rsrh016.png', category: 'chair' },
  { filename: 'rsrh017.png', category: 'chair' },
  { filename: 'rsrh018.png', category: 'chair' },
  { filename: 'rsrh019.png', category: 'plant' },
  { filename: 'rsrh020.png', category: 'plant' },
  { filename: 'rsrh021.png', category: 'plant' },
  { filename: 'rsrh022.png', category: 'plant' },
  { filename: 'rsrh023.png', category: 'plant' },
  { filename: 'rsrh024.png', category: 'plant' },
  { filename: 'rsrh025.png', category: 'table_medium' },
  { filename: 'rsrh026.png', category: 'table_medium' },
  { filename: 'rsrh027.png', category: 'table_medium' },
  { filename: 'rsrh028.png', category: 'misc' }, // balloon
  { filename: 'rsrh029.png', category: 'misc' }, // balloon
  { filename: 'rsrh030.png', category: 'misc' }, // balloon
  { filename: 'rsrh035.png', category: 'misc' }, // vertical fence
  { filename: 'rsrh036.png', category: 'misc' }, // horizontal fence
  { filename: 'rsrh039.png', category: 'misc' }, // mud ball
  { filename: 'rsrh033.png', category: 'misc' },
  { filename: 'rsrh037.png', category: 'misc' },
  { filename: 'rsrh040.png', category: 'misc' },
  { filename: 'rsrh041.png', category: 'misc' },
  { filename: 'rsrh042.png', category: 'misc' },
  { filename: 'rsrh043.png', category: 'misc' },
  { filename: 'rsrh044.png', category: 'misc' },
  { filename: 'rsrh031.png', category: 'misc' }, // red tent
  { filename: 'rsrh032.png', category: 'misc' }, // blue tent
  { filename: 'rsrh034.png', category: 'misc' }, // slide
  { filename: 'rsrh038.png', category: 'misc' }, // platform
  { filename: 'rsrh045.png', category: 'device' },
  { filename: 'rsrh046.png', category: 'device' },
  { filename: 'rsrh047.png', category: 'device' },
  { filename: 'rsrh048.png', category: 'floor_small' },
  { filename: 'rsrh049.png', category: 'floor_small' },
  { filename: 'rsrh050.png', category: 'floor_small' },
  { filename: 'rsrh051.png', category: 'floor_small' },
  { filename: 'rsrh059.png', category: 'floor_large' },
  { filename: 'rsrh060.png', category: 'floor_large' },
  { filename: 'rsrh061.png', category: 'floor_large' },
  { filename: 'rsrh062.png', category: 'floor_large' },
  { filename: 'rsrh063.png', category: 'floor_large' },
  { filename: 'rsrh064.png', category: 'floor_large' },
  { filename: 'rsrh065.png', category: 'floor_large' },
  { filename: 'rsrh066.png', category: 'wall' },
  { filename: 'rsrh067.png', category: 'wall' },
  { filename: 'rsrh068.png', category: 'wall' },
  { filename: 'rsrh069.png', category: 'wall' },
  { filename: 'rsrh070.png', category: 'wall' },
  { filename: 'rsrh071.png', category: 'wall' },
  { filename: 'rsrh072.png', category: 'wall' },
  { filename: 'rsrh073.png', category: 'wall' },
  { filename: 'rsrh074.png', category: 'wall' },
  { filename: 'rsrh075.png', category: 'wall' },
  { filename: 'rsrh076.png', category: 'doll_small' },
  { filename: 'rsrh077.png', category: 'doll_small' },
  { filename: 'rsrh078.png', category: 'doll_small' },
  { filename: 'rsrh079.png', category: 'doll_small' },
  { filename: 'rsrh080.png', category: 'doll_small' },
  { filename: 'rsrh081.png', category: 'doll_small' },
  { filename: 'rsrh082.png', category: 'doll_small' },
  { filename: 'rsrh083.png', category: 'doll_small' },
  { filename: 'rsrh084.png', category: 'doll_small' },
  { filename: 'rsrh085.png', category: 'doll_small' },
  { filename: 'rsrh086.png', category: 'doll_small' },
  { filename: 'rsrh087.png', category: 'doll_small' },
  { filename: 'rsrh088.png', category: 'doll_small' },
  { filename: 'rsrh089.png', category: 'doll_small' },
  { filename: 'rsrh090.png', category: 'doll_small' },
  { filename: 'rsrh091.png', category: 'doll_small' },
  { filename: 'rsrh092.png', category: 'doll_small' },
  { filename: 'rsrh093.png', category: 'doll_small' },
  { filename: 'rsrh094.png', category: 'doll_small' },
  { filename: 'rsrh095.png', category: 'doll_small' },
  { filename: 'rsrh096.png', category: 'doll_small' },
  { filename: 'rsrh097.png', category: 'doll_small' },
  { filename: 'rsrh098.png', category: 'doll_small' },
  { filename: 'rsrh099.png', category: 'doll_small' },
  { filename: 'rsrh100.png', category: 'doll_small' },
  { filename: 'rsrh101.png', category: 'cushion' },
  { filename: 'rsrh102.png', category: 'cushion' },
  { filename: 'rsrh103.png', category: 'cushion' },
  { filename: 'rsrh104.png', category: 'cushion' },
  { filename: 'rsrh105.png', category: 'cushion' },
  { filename: 'rsrh106.png', category: 'cushion' },
  { filename: 'rsrh107.png', category: 'cushion' },
  { filename: 'rsrh108.png', category: 'cushion' },
  { filename: 'rsrh109.png', category: 'cushion' },
  { filename: 'rsrh110.png', category: 'cushion' },
  { filename: 'rsrh111.png', category: 'doll_large' },
  { filename: 'rsrh112.png', category: 'doll_large' },
  { filename: 'rsrh113.png', category: 'doll_large' },
  { filename: 'rsrh114.png', category: 'doll_large' },
  { filename: 'rsrh115.png', category: 'doll_large' },
  { filename: 'rsrh116.png', category: 'doll_large' },
  { filename: 'rsrh117.png', category: 'doll_large' },
  { filename: 'rsrh118.png', category: 'doll_large' },
  { filename: 'rsrh119.png', category: 'doll_large' },
  { filename: 'rsrh120.png', category: 'doll_large' },
  { filename: 'laptop.png', category: 'device', unofficial: true },
  { filename: 'pc.png', category: 'device', unofficial: true },
  { filename: 'gamecube.png', category: 'device', unofficial: true },
  { filename: 'map.png', category: 'wall', unofficial: true },
  { filename: 'pikachu_poster.png', category: 'wall', unofficial: true },
  { filename: 'plant1.png', category: 'plant', unofficial: true },
  { filename: 'plant2.png', category: 'plant', unofficial: true },
];

export const categories = [
  { id: 'table_small', label: 'tables (small)', items: items.filter(i => i.category === 'table_small') },
  { id: 'table_medium', label: 'tables (medium)', items: items.filter(i => i.category === 'table_medium') },
  { id: 'table_large', label: 'tables (large)', items: items.filter(i => i.category === 'table_large') },
  { id: 'chair', label: 'chairs', items: items.filter(i => i.category === 'chair') },
  { id: 'plant', label: 'plants', items: items.filter(i => i.category === 'plant') },
  { id: 'device', label: 'devices', items: items.filter(i => i.category === 'device') },
  { id: 'floor_small', label: 'small mats', items: items.filter(i => i.category === 'floor_small') },
  { id: 'floor_large', label: 'large mats', items: items.filter(i => i.category === 'floor_large') },
  { id: 'wall', label: 'posters', items: items.filter(i => i.category === 'wall') },
  { id: 'cushion', label: 'cushions', items: items.filter(i => i.category === 'cushion') },
  { id: 'doll_small', label: 'small dolls', items: items.filter(i => i.category === 'doll_small') },
  { id: 'doll_large', label: 'large dolls', items: items.filter(i => i.category === 'doll_large') },
  { id: 'misc', label: 'miscellaneous', items: items.filter(i => i.category === 'misc') },
];
